<template>
  <div>
    <a-modal v-model="modalVisible" :title="modalTitle" :width="1250" :centered="true" :body-style="modalBodyStyle">
      <template slot="footer">
        <a-button @click="modalVisible=false">{{showType=='detail' ? '关闭' : '取消'}}</a-button>
        <a-button v-if="showType=='add' || showType=='edit'|| showType=='paste'" type="primary" @click="confirm()">提交</a-button>
      </template>
      <a-form-model ref="modalForm" layout="inline" class="form-in-twocolumns" :model="formData" :rules="formRules" :label-col="{span:6}" :wrapper-col="{span:16}">
        <a-form-model-item label="项目名称" prop="monitorpointnum">
          <a-select :disabled="showType=='detail'" v-model="formData.monitorpointnum" placeholder="请选择" show-search :filter-option="filterOption">
            <a-select-option v-for="(item, index) in monitorpointList" :key="index" :value="item.monitorpointnum">{{item.monitorpointname}}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="类别" prop="category">
          <a-select :disabled="showType=='detail'" v-model="formData.category">
            <a-select-option value="设施设备">设施设备</a-select-option>
            <a-select-option value="安全警示">安全警示</a-select-option>
            <a-select-option value="交通指引">交通指引</a-select-option>
            <a-select-option value="公益">公益</a-select-option>
            <a-select-option value="人员">人员</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="子类别" prop="subcategory">
          <a-select :disabled="showType=='detail'" v-model="formData.subcategory">
            <a-select-option v-if="formData.category==='设施设备'" value="标识卡">标识卡</a-select-option>
            <a-select-option v-if="formData.category==='设施设备'" value="状态标识">状态标识</a-select-option>
            <a-select-option v-if="formData.category==='设施设备'" value="功能标识">功能标识</a-select-option>
            <a-select-option v-if="formData.category==='设施设备'" value="管理标识">管理标识</a-select-option>
            <a-select-option v-if="formData.category==='安全警示'" value="禁止标识">禁止标识</a-select-option>
            <a-select-option v-if="formData.category==='安全警示'" value="警告标识">警告标识</a-select-option>
            <a-select-option v-if="formData.category==='安全警示'" value="提示标识">提示标识</a-select-option>
            <a-select-option v-if="formData.category==='安全警示'" value="指令标识">指令标识</a-select-option>
            <a-select-option v-if="formData.category==='交通指引'" value="导向标识">导向标识</a-select-option>
            <a-select-option v-if="formData.category==='交通指引'" value="分隔标识">分隔标识</a-select-option>
            <a-select-option v-if="formData.category==='交通指引'" value="移动指示/告知标识">移动指示/告知标识</a-select-option>
            <a-select-option v-if="formData.category==='公益'" value="宣传标识">宣传标识</a-select-option>
            <a-select-option v-if="formData.category==='公益'" value="温馨提示">温馨提示</a-select-option>
            <a-select-option v-if="formData.category==='公益'" value="植物标识">植物标识</a-select-option>
            <a-select-option v-if="formData.category==='人员'" value="工牌">工牌</a-select-option>
            <a-select-option v-if="formData.category==='人员'" value="肩章、臂章">肩章、臂章</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="使用范围" prop="use_scope">
          <a-input :disabled="showType=='detail'" v-model.trim="formData.use_scope" />
        </a-form-model-item>
        <a-form-model-item label="材质" prop="material_quality">
          <a-input :disabled="showType=='detail'" v-model.trim="formData.material_quality" />
        </a-form-model-item>
        <a-form-model-item label="数量" prop="quantity">
          <a-input :disabled="showType=='detail'" v-model.trim="formData.quantity" />
        </a-form-model-item>
        <a-form-model-item label="备注" prop="remark">
          <textarea :disabled="showType=='detail'" v-model.trim="formData.remark" style="width: 390px"/>
        </a-form-model-item>
        <a-form-model-item label="图片" prop="pic_url">
          <a-upload
              :disabled="showType==='detail'"
              name="fileList"
              :headers="uploadHeaders"
              :data="uploadBusinessManagement"
              list-type="picture-card"
              action="/upload"
              :file-list="picList"
              @preview="handlePreview(formData.pic_url)"
              @change="picUpload">
            <a-button v-if="showType!='detail'"><a-icon type="upload" /> 点击上传图片 </a-button>
          </a-upload>
          <h5 style="color: red">注意：上传的图片格式应为jpg/png格式</h5>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
    <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel" width="1080px">
      <img alt="example" style="width: 100%" :src="previewImage" />
    </a-modal>
  </div>
</template>
<script>
import moment from 'moment'
import {mapGetters, mapState} from 'vuex'
import areaselect from '@/mixins/areaselect'
import deptselect from '@/mixins/deptselect'
import {getMonitorPointNameListByCondition} from "A/monitoring";
import {addLedgerManageIdentification, getLedgerManageIdentificationByCondition, modifyLedgerManageIdentification} from "A/businessmanagement";
export default {
  mixins: [areaselect, deptselect],
  props: {
    visible: {
      default: false
    },
    showType: {
      default: 'add'
    },
    detailData: {
      default: null
    }
  },
  data() {
    return {
      moment,
      modalBodyStyle: {
        height: '480px',
        overflowY: 'auto',
      },
      modalVisible: false,
      formData: {
        monitorpointnum: '',
        category:'',
        subcategory:'',
        material_quality:'',
        use_scope:'',
        quantity:'',
        pic_url:'',
        remark:'',
        create_time:"",
        creator:"",
      },
      //这里面的数据属于必填项
      formRules: {
        monitorpointnum: [{required: true, message: '请选择项目'}],
        category: [{required: true, message: '请选择类别'}],
        subcategory: [{required: true, message: '请选择子类别'}],
        quantity: [{required: true, message: '请输入数量'}],
      },
      monitorpointList:[],
      baseurl:'https://fda.u-lake.com/',
      picList: [],
      previewImage: '',
      previewVisible:false,
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    }),
    ...mapGetters(['uploadHeaders', 'uploadBusinessManagement']),
    modalTitle() {
      if(this.showType == 'add') {
        return '新增'
      }else if(this.showType == 'edit'||this.showType == 'paste') {
        return '修改'
      }else if(this.showType == 'detail') {
        return '详情'
      }else {
        return '';
      }
    },
  },
  watch: {
    visible(val) {
      if(val) {
        this.modalVisible = true;
        this.resetData();
        this.getMonitorPointNameList();
        this.initDetail();
      }
    },
    modalVisible(val) {
      if(!val) {
        this.resetData();
        this.$emit('get-operation-result', 'success', '操作成功');
        this.$emit('update:visible', false);
      }
    },
  },
  created() {
    this.modalVisible = this.visible;
  },
  methods: {
    getMonitorPointNameList(){
      getMonitorPointNameListByCondition({userdepsid:this.userInfo.userdepid}).then(res=>{
        if(res&&res.returncode=='0'){
          this.monitorpointList=res.item
        }
      })
    },
    resetData() {
      if (this.$refs.modalForm) {
        this.$refs.modalForm.resetFields();
      }
    },
    initDetail() {
      if (this.detailData && this.detailData.ledger_id) {
        if(this.showType == 'edit' || this.showType == 'detail' || this.showType === 'paste') {
          this.$nextTick(() => {
            let params = {
              ledger_id: this.detailData.ledger_id
            }
            getLedgerManageIdentificationByCondition(params).then(res => {
              if (res && res.returncode == '0') {
                this.formData = res.item[0];
                if (this.formData.pic_url) {
                  this.previewImage=this.baseurl+this.formData.pic_url
                  let nameIndex = this.formData.pic_url.lastIndexOf('/');
                  if (nameIndex < 0) {
                    nameIndex = this.formData.pic_url.lastIndexOf('\\');
                  }
                  let fileName = this.formData.pic_url.substr(nameIndex + 1);
                  this.picList = [{
                    uid: 'pic_urlid',
                    name: fileName,
                    status: 'done',
                    url: this.formData.pic_url,
                  }];
                }
              }
            })
          })
        }
      }
    },
    confirm() {
      if (this.showType == 'add' || this.showType == 'edit' || this.showType === 'paste') {
        this.$refs.modalForm.validate(valid => {
          if (valid) {
            this.formData.create_time=moment(new Date()).format("YYYYMMDDHHmmss")
            this.formData.creator=this.userInfo.username
            let params = {
              ...this.formData,
            };
            if (this.showType == 'add' || this.showType === 'paste') {
              this.showLoading();
              addLedgerManageIdentification(params).then(res => {
                this.hideLoading();
                if (res && res.returncode == '0') {
                  this.$message.success('操作成功');
                  this.$emit('get-operation-result', 'success', '操作成功');
                  this.modalVisible = false;
                } else {
                  this.$message.error(res.errormsg || '操作失败');
                }
              })
            } else if(this.showType == 'edit'){
              this.showLoading();
              modifyLedgerManageIdentification(params).then(res => {
                this.hideLoading();
                if (res && res.returncode == '0') {
                  this.$message.success('操作成功');
                  this.$emit('get-operation-result', 'success', '操作成功');
                  this.modalVisible = false;
                } else {
                  this.$message.error(res.errormsg || '操作失败');
                }
              })
            }else{
              this.$message.warning('请确认信息填写完整')
            }
          } else {
            this.$message.warning('请确认信息填写完整')
            return false;
          }
        })
      } else {
        this.modalVisible = false;
      }
    },
    picUpload(info) {
      let fileList = [...info.fileList];
      fileList = fileList.slice(-1);
      fileList = fileList.map(file => {
        if (file.response) {
          file.url = file.response.urlPath;
          this.formData.pic_url = file.response.urlPath;
        }
        return file;
      });
      this.picList = fileList;
    },
    async handlePreview(url) {
      this.previewImage = this.baseurl + url;
      this.previewVisible = true;
    },
    handleCancel() {
      this.previewVisible = false;
    },
    filterOption(input, option) {
      return (
          option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
  }
}
</script>
<style lang="scss" scoped>
.select-drop-down-platform {
  width: 240px;
}
</style>
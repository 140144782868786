<template>
  <page-container :title="pageTitle" :breadcrumb="breadcrumb">
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="simple-query">
          <a-form-model ref="queryForm" :model="queryParams" layout="inline" class="query-form">
            <a-form-model-item label="公司/项目" prop="monitorpointname" style="width: 25%">
              <a-cascader :field-names="{ label: 'userdepname', value: 'userdepid', children: 'children' }" :options="userInfo.usertype!='0'?deptOptions.filter(item => item.deptype=='1'):deptOptionsAll.filter(item => item.deptype=='1')" v-model="userdepidCascaderSelected" :load-data="userInfo.usertype!='0'?deptOnLoadChildren:deptOnLoadChildrenAll" placeholder="请选择公司/项目" :change-on-select="true" :allow-clear="false"></a-cascader>
            </a-form-model-item>
            <a-form-model-item label="类别" prop="category" style="width: 25%">
              <a-select v-model="queryParams.category">
                <a-select-option value="设施设备">设施设备</a-select-option>
                <a-select-option value="安全警示">安全警示</a-select-option>
                <a-select-option value="交通指引">交通指引</a-select-option>
                <a-select-option value="公益">公益</a-select-option>
                <a-select-option value="人员">人员</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="子类别" prop="subcategory" style="width: 25%">
              <a-select v-model="queryParams.subcategory">
                <a-select-option v-if="queryParams.category==='设施设备'" value="标识卡">标识卡</a-select-option>
                <a-select-option v-if="queryParams.category==='设施设备'" value="状态标识">状态标识</a-select-option>
                <a-select-option v-if="queryParams.category==='设施设备'" value="功能标识">功能标识</a-select-option>
                <a-select-option v-if="queryParams.category==='设施设备'" value="管理标识">管理标识</a-select-option>
                <a-select-option v-if="queryParams.category==='安全警示'" value="禁止标识">禁止标识</a-select-option>
                <a-select-option v-if="queryParams.category==='安全警示'" value="警告标识">警告标识</a-select-option>
                <a-select-option v-if="queryParams.category==='安全警示'" value="提示标识">提示标识</a-select-option>
                <a-select-option v-if="queryParams.category==='安全警示'" value="指令标识">指令标识</a-select-option>
                <a-select-option v-if="queryParams.category==='交通指引'" value="导向标识">导向标识</a-select-option>
                <a-select-option v-if="queryParams.category==='交通指引'" value="分隔标识">分隔标识</a-select-option>
                <a-select-option v-if="queryParams.category==='交通指引'" value="移动指示/告知标识">移动指示/告知标识</a-select-option>
                <a-select-option v-if="queryParams.category==='公益'" value="宣传标识">宣传标识</a-select-option>
                <a-select-option v-if="queryParams.category==='公益'" value="温馨提示">温馨提示</a-select-option>
                <a-select-option v-if="queryParams.category==='公益'" value="植物标识">植物标识</a-select-option>
                <a-select-option v-if="queryParams.category==='人员'" value="工牌">工牌</a-select-option>
                <a-select-option v-if="queryParams.category==='人员'" value="肩章、臂章">肩章、臂章</a-select-option>
              </a-select>
            </a-form-model-item>
            <div class="query-btns" style="float: right;width: 20%">
              <a-button @click="resetQueryParams">重置</a-button>
              <a-button type="primary" class="after-btn" @click="getTableData(true)">查询</a-button>
              <a-button type="link" @click="showAdvanced=!showAdvanced">展开<a-icon :type="showAdvanced?'up':'down'"></a-icon></a-button>
            </div>
            <div v-show="showAdvanced" class="advanced-query">
            </div>
          </a-form-model>
        </div>
      </div>
    </div>
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="table-header">
          <h3 class="table-title">{{pageTitle}}</h3>
          <div class="table-btns">
<!--            <a-button v-if="btnList.includes('导出')" @click="exportModal" style="margin-right: 10px"><a-icon type="export"></a-icon>批量导出</a-button>-->
            <a-button v-if="btnList.includes('新增')" @click="addRecord" style="margin-right: 10px" type="primary"><a-icon type="plus"></a-icon>新增</a-button>
            <a-icon type="redo" class="after-btn table-refresh" @click="getTableData()"></a-icon>
          </div>
        </div>
        <a-table :customRow="customRow" :columns="tableColumns" :data-source="tableData" row-key="ledger_id" :loading="tableLoading" :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')" style="margin-top: 20px;" :pagination="false">
          <span slot="pic_url" slot-scope="value,record"><a>{{'图片'}}</a></span>
          <span slot="operation" slot-scope="value, record">
            <a-dropdown>
              <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                <a-icon type="menu"/>
              </a>
              <a-menu slot="overlay" @click="operationClick">
                <a-menu-item :key="'detail-'+record.ledger_id">详情</a-menu-item>
                <a-menu-item v-if="btnList.includes('修改')" :key="'edit-'+record.ledger_id">修改</a-menu-item>
                <a-menu-item v-if="btnList.includes('删除')" :key="'delete-'+record.ledger_id">删除</a-menu-item>
              </a-menu>
            </a-dropdown>
          </span>
        </a-table>
        <a-pagination v-model="currentPage" :total="pagination.total" :page-size="pagination.pageSize" show-size-changer @change="pageChange" @showSizeChange="onShowSizeChange" :show-total="(total, range) => `共${total}条`" style="margin-top: 20px; text-align: right;">
          <template slot="buildOptionText" slot-scope="props">
            <span>{{ props.value }}条/页</span>
          </template>
        </a-pagination>
      </div>
    </div>
    <add-or-edit-modal :visible.sync="modalVisible" :show-type="modalShowType" :detailData="modalDetailData" @get-operation-result="getTableData"></add-or-edit-modal>
  </page-container>
</template>
<script>
import moment from "moment";
import {mapGetters, mapState} from "vuex";
import pagination from "@/mixins/pagination";
import {getCache, getItemFromArrayByKey, getTimeRange} from "U/index";
import deptselect from "@/mixins/deptselect";
import addOrEditModal from "V/businessManagement/standingBook/ledgerManageIdentification/addOrEditModal";
import {
  deleteLedgerManageIdentification,
  getLedgerManageIdentificationByCondition,
} from "A/businessmanagement";
export default {
  name: "ledgerManageIdentification",
  mixins: [deptselect,pagination],
  components: {
    addOrEditModal,
  },
  data() {
    return {
      moment,
      tableData: [],
      tableLoading: false,
      showAdvanced: false,
      queryParams:{
        monitorpointname:'',
        category:'',
        subcategory:'',
      },
      tableColumns:[
        {
          title: '项目名称',
          dataIndex: 'monitorpointname',
          key: 'monitorpointname',
          ellipsis: true,
        },
        {
          title: '类别',
          dataIndex: 'category',
          key: 'category',
          ellipsis: true,
        },
        {
          title: '子类别',
          dataIndex: 'subcategory',
          key: 'subcategory',
          ellipsis: true,
        },
        {
          title: '使用范围',
          dataIndex: 'use_scope',
          key: 'use_scope',
          ellipsis: true,
        },
        {
          title: '材质',
          dataIndex: 'material_quality',
          key: 'material_quality',
          ellipsis: true,
        },

        {
          title: '数量',
          dataIndex: 'quantity',
          key: 'quantity',
          ellipsis: true,
        },
        // {
        //   title: '图片',
        //   dataIndex: 'pic_url',
        //   key: 'pic_url',
        //   ellipsis: true,
        //   scopedSlots: { customRender: 'pic_url' },
        // },
        {
          title: '备注',
          dataIndex: 'remark',
          key: 'remark',
          ellipsis: true,
        },
        {
          title: '操作',
          dataIndex: 'operation',
          key: 'operation',
          ellipsis: true,
          align:'center',
          scopedSlots: { customRender: 'operation' },
          width: 70
        },
      ],
      userdepidCascaderSelected: [],
      modalVisible: false,
      modalShowType: '',
      modalDetailData: null,

      //操作按钮权限
      btnList:[],
      menu:{},
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    }),
    ...mapGetters(['operationMenuTree']),
    pageTitle() {
      return "管理标识台账"
    },
    breadcrumb() {
      const pages = [{name:"业务管理",path:""}]
      pages.push({name:"台账管理",path:""})
      pages.push({name:"标识台账",path:""})
      pages.push({name:this.pageTitle,path:""})
      return pages
    },
  },
  watch:{
    userdepidCascaderSelected(val) {
      if(val && val.length) {
        this.queryParams.userdepsid = val[val.length-1];
      }else {
        this.queryParams.userdepsid = '';
      }
    },
  },
  created() {
    this.menu=getItemFromArrayByKey(this.operationMenuTree,"menuid",this.$route.params.menuid,"children")
    for(let i=0;i<this.menu.children.length;i++){//获取菜单页面内操作权限
      this.btnList.push(this.menu.children[i].menuname)
    }
    this.getTableData()
    this.initDeptOptionsAll();
  },
  methods:{
    getTableData(firstPage) {
      if(firstPage) {
        this.currentPage = 1;
      }
      this.tableLoading = true;
      let params = {
        ...this.queryParams,
        pageno: this.currentPage,
        pagesize: this.pagination.pageSize
      };
      getLedgerManageIdentificationByCondition(params).then(res => {
        this.tableLoading = false;
        if(res && res.returncode == '0') {
          this.tableData = res.item;
          this.pagination.total = res.count;
        }
      }).catch(err => {
        this.tableLoading = false;
      })
    },
    resetQueryParams() {
      this.userdepidCascaderSelected=[]
      this.$refs.queryForm.resetFields();
    },
    operationClick({key}){
      let arr = key.split('-');
      let type = arr[0];
      let ledger_id = arr[1];
      let record = getItemFromArrayByKey(this.tableData, 'ledger_id', ledger_id);
      if(type == 'delete') {
        this.deleteConfirm(ledger_id, record)
      }else{
        this.showModal(type,record)
      }
    },
    showModal(type,record){
      if(type=='detail'){
        this.modalDetailData=record
        this.modalShowType=type
        this.modalVisible=true
      }else if(type=='edit'){
        this.modalDetailData=record
        this.modalShowType=type
        this.modalVisible=true
      }
    },
    addRecord(){
      this.modalVisible=true
      this.modalShowType='add'
    },
    exportModal(){
      this.$confirm('你确认要按照所选筛选条件导出文件吗？',{
        type:'warning',
        centered:true,
      }).then(()=>{
        this.exportBusDevice()
      }).catch(()=>{
      });
    },
    exportBusDevice(){
      let params = {
        ...this.queryParams,
        is_history:'0',
      }
      this.showLoading();
      exportBusDeviceExcel(params).then((res)=> {
        this.hideLoading();
        this.$message.success("导出成功")
      }).catch(()=>{
        this.hideLoading();
      })
    },
    deleteConfirm(value, record) {
      this.$confirm('确定要删除该数据?',{
        type:'warning',
        centered:true,
      }).then(()=>{
        this.delete(record.ledger_id);
      }).catch(()=>{

      }) ;
    },
    delete(ledger_id) {
      this.showLoading();
      if(ledger_id) {
        let params = {
          ledger_id
        };
        deleteLedgerManageIdentification(params).then(res => {
          this.hideLoading();
          if(res && res.returncode == '0') {
            this.$message.success('操作成功');
            this.getTableData();
          }else {
            this.$message.error(res.errormsg||'操作失败');
          }
        })
      }
    },
    deleteBatch() {
      if(this.batchrowids) {
        this.deleteBatchConfirm();
      }else {
        this.$message.warning('请先选择删除项');
      }
    },
    deleteBatchConfirm() {
      this.$confirm('确定要删除选中数据?',{
        type:'warning',
        centered:true,
      }).then(()=>{
        this.delete(this.batchrowids);
      }).catch(()=>{

      }) ;
    },
    rowSelectChange(selectedRowKeys, selectedRows) {
      this.batchrowids = selectedRowKeys.join('|');
    },
    customRow(record, index){
      return {
        on: { // 事件
          click: () => {
            this.modalDetailData=record
            this.modalShowType="detail"
            this.modalVisible=true
          },
        },
      }
    },
  },
}
</script>
<style scoped>

</style>